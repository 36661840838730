.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.canvas-wrapper,
#scatter-gl-container {
  display: inline-block;
  vertical-align: top;
}

#scatter-gl-container {
  border: solid 1px black;
  position: relative;
}

/* center the canvas within its wrapper */
#scatter-gl-container canvas {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  position: absolute;
}

.progress-bar {
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
}

.gender-box {
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  cursor: pointer;
}

.lazer {
  box-shadow: 0 3px 6px 0 #ffc000;
  background-color: #ffc000;
  height: 4px;
  position: absolute;
  left: 0;
  animation: lazerAnimation 4s infinite;
}

@keyframes lazerAnimation {
  0% {
    top: 0;
  }
  50% {
    top: 99%;
  }
  100% {
    top: 0;
  }
}
#video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.blur-background {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.6);
}

.img-instruction {
  background-size: contain !important;
  margin-bottom: 16px;
  margin-top: 16px;
}
.flex-1 {
  flex: 1;
}
.age-input {
  border: 1px #cecece solid;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  height: 56px;
  width: 200px;
}

.hidden-input {
  display: none;
}

.age-input-btn {
  min-width: 56px;
  height: 56px;
  background: #736bf2;
  border-radius: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.age-input-btn img {
  height: 16px;
}

.age-input-inpt {
  border: none;
  background: transparent;
  width: 100%;
  border-radius: 100px 0 0 100px;
  outline: none;
  padding-left: 16px;
  text-align: center;
}

textarea {
  background: transparent;
  width: 90%;
  border-radius: 8px;
  outline: none;
  padding-left: 16px;
  font-size: 16px;
  padding: 16px;
  height: 70%;
  border: 1px #cecece solid;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
}

.input-simple-text {
  border: none;
  border: 1px #cecece solid;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  background: transparent;
  width: 90%;
  border-radius: 8px;
  outline: none;
  padding-left: 16px;
  font-size: 16px;
  height: 56px;
  padding-left: 16px;
}

.social-input {
  border: none;
  border: 1px #cecece solid;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  background: transparent;
  width: 90%;
  border-radius: 8px;
  outline: none;
  padding-left: 16px;
  font-size: 16px;
  height: 56px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.social-input .logo {
  width: 32px;
  min-width: 32px;
}

.social-input input {
  flex: 1;
  padding-left: 8px;
  height: 100%;
  outline: none;
  border: none;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(54, 54, 54);
}
@media (min-width: 992px) {
  .mw-lg-80 {
    max-width: 80%;
  }
  .h-sm-45 {
    margin-right: 16px;
    margin-left: 16px;
  }
}
@media (max-width: 992px) {
  .h-sm-45 {
    height: 45%;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.h-sm-45 {
  outline: none;
  color: black;
}
.h-sm-45:hover {
  outline: none !important;
  color: black !important;
  text-decoration: none !important;
}
/* 
* {
  color: #050508;
  font-family: "Lato";
} */
.app-box {
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.heading {
  font-family: "Barlow";
  color: #050508;
}
.text {
  font-family: "Barlow";
  color: #050508;
}
.button-light {
  color: #3a3838;
  border: 1px solid #b2b2b2;
}

.progress-active{
  background: #363636
}
.button {
  color: #f9f9f9;
  background: #235dde;
}

body.dark {
  background: #050508;
}
.dark input,
.dark textarea {
  color: white;
}
.dark .app-box {
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #050508;
}
.dark .heading {
  font-family: "Barlow";
  color: #f2f2f2;
}
.dark .texting {
  font-family: "Barlow";
  color: #f2f2f2;
}
.dark .button-light {
  color: #235dde;
  border: 1px solid #235dde;
}
.dark .button-light img {
  display: none !important;
}

.dark .progress-active {
  background: #e5f754;
}
.dark .button {
  color: #050508;
  background: #e5f754;
}

.dark .age-input-btn {
  background: #e5f754;
}
